import React from "react"

// Libraries
import styled from "styled-components"
import PropTypes from "prop-types"

// Utils
import { colors } from "utils/variables"

// Components
import BlogCard from "components/blog-card"
import Container from "components/container"

const StyledReletedArticles = styled.section`
  padding: 120px 0;
  background: ${colors.silver__pale};

  .title-section {
    margin-bottom: 40px;
  }
`

const RelatedArticles = (props) => {
  const { relatedArticles } = props

  return (
    <StyledReletedArticles>
      <Container>
        <h2 className="title-section h1 text-center">Also check out</h2>
        {relatedArticles && (
          <div className="row">
            {relatedArticles.map((article) => (
              <div className="col-12 col-sm-6 col-md-4 mb-5">
                <BlogCard {...article} />
              </div>
            ))}
          </div>
        )}
      </Container>
    </StyledReletedArticles>
  )
}

RelatedArticles.propTypes = {
  relatedArticles: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      categories: PropTypes.arrayOf(PropTypes.string).isRequired,
      featuredImage: PropTypes.shape({}).isRequired,
      excerpt: PropTypes.shape({ excerpt: PropTypes.string.isRequired })
        .isRequired,
      slug: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default RelatedArticles
