import React from "react"

// Libraries
import PropTypes from "prop-types"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Utils
import { colors } from "utils/variables"
import breakpoints from "utils/breakpoints"

// Icon
import Icon from "assets/icons/blog/icon-linkedin.inline.svg"

const StyledBlogAuthor = styled.div`
  background: ${colors.silver__extra_pale};
  border-radius: 20px;
  padding: 60px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${breakpoints.medium`
    padding: 65px 77px;
    flex-direction: row;
    align-items: start;
    justify-content: start;
  `}

  .img {
    border: 2px solid ${colors.turquoise__primary};
    border-radius: 50%;
    width: 120px;
    height: 120px;
    padding: 8px;
    margin-bottom: 40px;
    flex-shrink: 0;

    ${breakpoints.medium`
      margin-right: 64px;
      margin-bottom: 0;
    `}
  }

  .name {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    text-decoration: none;
    color: ${colors.navy__extra_dark};

    svg {
      margin-left: 16px;
    }
  }

  .jobPosition {
    color: ${colors.turquoise__primary};
    margin-bottom: 16px;
  }
`

const BlogAuthor = (props) => {
  const { profilePicture, name, jobPosition, shortBio, linkedInProfile } = props
  return (
    <StyledBlogAuthor>
      {profilePicture && (
        <div className="img">
          <GatsbyImage
            image={getImage(profilePicture)}
            imgStyle={{ borderRadius: "50%" }}
            alt=""
          />
        </div>
      )}
      <div>
        <p className="h2 name">
          {name}{" "}
          {linkedInProfile && (
            <a
              href={linkedInProfile}
              className="name"
              target="_black"
              rel="noopener noreferrer"
            >
              <Icon />
            </a>
          )}
        </p>
        <p className="jobPosition text--large">{jobPosition}</p>
        {shortBio && <p className="text--large">{shortBio.shortBio}</p>}
      </div>
    </StyledBlogAuthor>
  )
}

BlogAuthor.propTypes = {
  profilePicture: PropTypes.shape({}),
  name: PropTypes.string.isRequired,
  jobPosition: PropTypes.string.isRequired,
  linkedInProfile: PropTypes.string,
  shortBio: PropTypes.string,
}

BlogAuthor.defaultProps = {
  linkedInProfile: null,
  profilePicture: null,
  shortBio: null,
}

export default BlogAuthor
