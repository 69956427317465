import React from "react"

// Libraries
import PropTypes from "prop-types"
import styled from "styled-components"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { GatsbyImage } from "gatsby-plugin-image"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container/"

// Icons
import IconBlockquote from "assets/icons/blog-post/icon-blockquote.svg"
import { colors } from "utils/variables"
import BlogAuthor from "components/blog-author"
import DownloadResource from "components/download-resource"

const StyledContent = styled.section`
  font-size: 20px;
  line-height: 28px;
  overflow: hidden;

  ${Container} {
    max-width: 928px;
  }

  .post__content {
    & > h1,
    & > h2,
    & > h3,
    & > h4,
    & > h5 {
      margin-top: 0 !important;
    }

    p {
      margin-bottom: 1em;

      a {
        color: ${colors.turquoise__primary};

        &:hover {
          color: ${colors.navy__dark};
        }
      }
    }

    ul {
      margin: 2em 0;
      // padding-left: 1em;

      li {
        margin-bottom: 16px;
        list-style: none;

        &:last-child {
          margin-bottom: 0;
        }

        p {
          display: inline;
        }
      }

      li:before {
        content: "•";
        display: inline;
        width: 1em;
        margin-right: 16px;
        color: ${colors.turquoise__primary};
        // position: relative;
        // max-width: 0px;
        // max-height: 0px;
        // left: -10px;
        // top: -0px;
        // font-size: 20px;
        // margin-left: -0.5rem;
      }
    }

    .h1,
    .h2,
    .h3,
    .h4 {
      margin: 72px 0 16px 0;

      & + ul {
        margin-top: 0;
      }
    }

    blockquote {
      position: relative;
      margin: 2em 0;
      padding: 36px 24px;
      background-color: ${colors.silver__extra_pale};
      font-size: 32px;
      line-height: 44px;
      border-radius: 20px;

      ${breakpoint.medium`
      padding: 72px 80px;
    `}

      &::before {
        content: url(${IconBlockquote});
        position: absolute;
        top: -12px;
        left: -31px;
        bottom: 0;
        display: inline-block;
      }
    }

    .image,
    iframe {
      width: 100%;
      margin: 2em 0;
    }

    .content__embed-entry {
      margin: 2em 0;

      section {
        padding: 0;
      }
    }
  }
`

const Content = (props) => {
  const { content, author } = props

  const renderOptions = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => (
        <h2 className="h1">{children}</h2>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <h2 className="h1">{children}</h2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <h3 className="h3">{children}</h3>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <h4 className="h4">{children}</h4>
      ),
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        const {
          data: { target },
        } = node

        return (
          <div className="content__embed-entry">
            <DownloadResource {...target} />
          </div>
        )
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const {
          data: {
            target: { gatsbyImageData, description },
          },
        } = node

        return (
          <GatsbyImage
            image={gatsbyImageData}
            className="image"
            imgStyle={{ width: "100%" }}
            alt={description}
          />
        )
      },
      [INLINES.HYPERLINK]: (node) => {
        const { data, content } = node

        if (data.uri.includes("https://www.youtube.com/watch?v=")) {
          const videoId = data.uri.split("https://www.youtube.com/watch?v=")[1]

          return (
            <iframe
              width="100%"
              style={{ aspectRatio: "16 / 9" }}
              src={`https://www.youtube.com/embed/${videoId}`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          )
        }

        return <a href={data.uri}>{content[0].value}</a>
      },
    },
  }

  return (
    <StyledContent>
      <Container>
        {content && (
          <div className="post__content">
            {renderRichText(content, renderOptions)}
          </div>
        )}
        {author && (
          <div className="mt-5">
            <BlogAuthor {...author} />
          </div>
        )}
      </Container>
    </StyledContent>
  )
}

Content.propTypes = {
  content: PropTypes.shape({
    raw: PropTypes.string.isRequired,
  }).isRequired,
  author: PropTypes.shape({
    name: PropTypes.string.isRequired,
    jobPosition: PropTypes.string.isRequired,
    profilePicture: PropTypes.shape({}),
    linkedInProfile: PropTypes.string,
    shortBio: PropTypes.string,
  }).isRequired,
}

export default Content
