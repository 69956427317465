import React from "react"

// Libraries
import PropTypes from "prop-types"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Components
import Container from "components/container/"
import CategoryBadge from "components/category-badge"

// Utils
import { colors } from "utils/variables"

const StyledHeader = styled.section`
  // ${Container} {
  //   position: relative;
  // }
  padding: 80px 0;
  position: relative;

  .header__author {
    span {
      &::after {
        content: "·";
        display: inline-block;
        margin: 0 8px;
        color: ${colors.silver__dark};
      }
    }
  }

  // .button {
  //   position: absolute;
  //   right: 0;

  //   svg {
  //     margin-left: 8px;
  //   }
  // }

  .image {
    flex-shrink: 0;
    margin-top: 64px;
  }
`

const Header = (props) => {
  const { title, featuredImage, blogCategories, author, publishDate } = props

  return (
    <StyledHeader>
      <Container>
        <div className="header__categories">
          {blogCategories &&
            blogCategories.map((category) => (
              <CategoryBadge
                to={`/blog/categories/${category.slug}`}
                className="me-3 mb-3"
              >
                {category.title}
              </CategoryBadge>
            ))}
        </div>

        <h1 className="h2 maxi mb-3">{title}</h1>
        <p className="header__author text--large mb-2">
          {author && <span>By {author.name}</span>}
        </p>
        <p className="color--silver__light">{publishDate}</p>

        <div>
          <GatsbyImage
            image={getImage(featuredImage)}
            className="image w-100"
            imgStyle={{ aspectRatio: "4/3", borderRadius: "16px" }}
            alt=""
          />
        </div>
      </Container>
    </StyledHeader>
  )
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  featuredImage: PropTypes.shape({}).isRequired,
  blogCategories: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    })
  ).isRequired,
  author: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  publishDate: PropTypes.string.isRequired,
}

export default Header
