import React from "react"

// Libraries
import PropTypes from "prop-types"
import styled from "styled-components"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { motion } from "framer-motion"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container/"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Button from "components/button"

const StyledDownloadResource = styled.section`
  padding: 60px 0;

  ${breakpoint.medium`
    padding: 120px 0;
  `}

  ${Container} {
    max-width: 928px;
  }

  .resource__card {
    border-radius: 16px;
    box-shadow: 2px 16px 40px rgba(110, 200, 205, 0.3);
    overflow: hidden;

    > div {
      width: 100%;

      ${breakpoint.small`
        width: 50%;
      `}
    }

    .card__content {
      padding: 32px 24px;

      ${breakpoint.medium`
        padding: 54px;
      `}

      h1 {
        margin-bottom: 8px;
      }

      p {
        margin-bottom: 24px;
      }
    }
  }

  ul {
    padding: 0 8px 0 1rem;
    margin: 1em 0;
    list-style: disc;
    // list-style-position: inside;

    li {
      margin-bottom: 8px;

      p {
        display: inline;
      }
    }
  }

  a {
    text-decoration: underline;
  }
`

const DownloadResource = (props) => {
  const { content, image, buttonLabel, resource, anchorId } = props

  const renderOptions = {
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => (
        <h2 className="h1 mb-4">{children}</h2>
      ),
    },
  }

  return (
    <StyledDownloadResource id={anchorId || "downloadResource"}>
      <Container>
        <motion.div
          initial={{ y: 32, scale: 0.95 }}
          whileInView={{ y: 0, scale: 1 }}
          viewport={{ once: true }}
          className="resource__card d-flex flex-wrap"
        >
          <GatsbyImage image={getImage(image)} alt="" />

          <div className="card__content d-flex align-items-center">
            <div>
              {content && renderRichText(content, renderOptions)}

              <Button type="link" external to={resource.file.url}>
                {buttonLabel}
              </Button>
            </div>
          </div>
        </motion.div>
      </Container>
    </StyledDownloadResource>
  )
}

DownloadResource.propTypes = {
  content: PropTypes.shape({
    raw: PropTypes.instanceOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  image: PropTypes.instanceOf(PropTypes.shape({})).isRequired,
  buttonLabel: PropTypes.string.isRequired,
  resource: PropTypes.shape({
    file: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired,
  }).isRequired,
  anchorId: PropTypes.string,
}

DownloadResource.defaultProps = {
  anchorId: null,
}

export default DownloadResource
