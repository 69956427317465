import React from "react"

// Libraries
import PropTypes from "prop-types"
import { graphql } from "gatsby"

// Layout
import Layout from "layouts/layout-primary"

// Components
import Content from "components/rich-content"
import SEO from "components/seo/"

// Sections
import Header from "sections/blog-post/header"
import RelatedArticles from "sections/blog/related-articles"

const BlogPost = (props) => {
  const {
    data: { contentfulBlogPosts: data },
  } = props

  const { title, excerpt, relatedArticles } = data

  return (
    <Layout>
      <SEO
        title={`${title} | Arena Analytics`}
        description={excerpt && excerpt.excerpt}
      />
      <Header {...data} />
      <Content {...data} />
      {relatedArticles && <RelatedArticles relatedArticles={relatedArticles} />}
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    contentfulBlogPosts: PropTypes.shape({
      title: PropTypes.string.isRequired,
      excerpt: PropTypes.shape({
        excerpt: PropTypes.string,
      }).isRequired,
      relatedArticles: PropTypes.shape({}).isRequired,
    }),
  }).isRequired,
}

export default BlogPost

export const query = graphql`
  query ($id: String!) {
    contentfulBlogPosts(id: { eq: $id }) {
      title
      content {
        raw
        references {
          ... on ContentfulDownloadResource {
            __typename
            contentful_id
            ...DownloadResource
          }
          ... on ContentfulAsset {
            __typename
            contentful_id
            gatsbyImageData(width: 928, quality: 100)
          }
        }
      }
      featuredImage {
        gatsbyImageData(width: 929, height: 527, quality: 100)
      }
      blogCategories {
        title
        slug
      }
      publishDate(formatString: "MMM D, YYYY")
      author {
        name
        jobPosition
        profilePicture {
          gatsbyImageData(width: 104, height: 104, quality: 100)
        }
        linkedInProfile
        shortBio {
          shortBio
        }
      }
      relatedArticles {
        ...BlogCard
      }
    }
  }
`
